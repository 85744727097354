
 #decUI, #incUI, #infoUI, #zoomUI {
   background-color: #fff;
   border: 2px solid #fff;
   border-radius: 8px;
   box-shadow: 0 2px 6px rgba(0,0,0,.3);
   float: left;
   margin-bottom: 22px;
   text-align: center;
 }
 #decUI, #incUI, #zoomUI{
   cursor: pointer;
 }
 #decText, #incText, #infoText, #zoomText{
   color: rgb(25,25,25);
   font-family: Roboto,Arial,sans-serif;
   font-size: 15px;
   line-height: 25px;
   padding-left: 5px;
   padding-right: 5px;
 }

 #incUI {
   margin-left: 6px;
 }

 #infoUI, #decUI {
   margin-left: 12px;
 }
