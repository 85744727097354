body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root{
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 600px)
{
  .removeWhenMobile{
    display: none !important;
  }
}

@media screen and (min-width: 601px) and (max-width: 1200px)
{
  .removeWhenTablet{
    display: none !important;
  }
}

@media screen and (min-width: 1201px)
{
  .removeWhenLaptop{
    display: none !important;
  }
}


.App{
  width: 100%;
  height: 100%;
}

.header{
  width: 100%;
  height: 45px;
  background-color: #db2828;
}

.notHeader{
  width: 100%;
  height: calc(100% - 45px);
}

@media screen and (min-width: 1201px){
  .MainPanel{
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 1200px){
  .MainPanel{
    width: 100%;
    height: 100%;
    overflow: scroll;
  }
}

@media screen and (min-width: 1201px){
  .leftPanel{
    float:left;
    width: 50%;
    height: 100%;
  }
}
@media screen and (max-width: 1200px){
  .leftPanel{
    width: 100%;
    height: auto;
  }
}

@media screen and (min-width: 1201px){
  .rightPanel{
    float:left;
    width: 50%;
    height: 100%;
  }
}
@media screen and (max-width: 1200px){
  .rightPanel{
    width: 100%;
    height: 90%;
  }
}

.dateDisplay{
  height: 40px;
  width: 100%;
}


.reportGrid{
  width: 100%;
  height: 100%;
  padding: 5px;
  display: grid;
  grid-gap: 5px;
  overflow: auto;
}

.fieldTag{
  text-align: left;
  font-size: 10px;
  margin: 0px;
}
.fieldValue{
  text-align: center;
  font-size: 14px;
  margin: 0px;
}

.fieldInforme {
  grid-column: 1;
  grid-row: 1;
  margin: 0px !important;
}

.fieldDate {
  grid-column: 2;
  grid-row: 1;
  margin: 0px !important;
}

.fieldHour {
  grid-column: 3;
  grid-row: 1;
  margin: 0px !important;
}

.fieldCompas {
  grid-column: 1;
  grid-row: 2;
  margin: 0px !important;

  display: grid;
  grid-gap: 10px;
}

@media screen and (min-width: 1201px){
  .angulos{
    display: inline-block;
  }
}
@media screen and (max-width: 1200px){
  .angulos{
    display: block;
  }
}

.fieldViento {
  grid-column: 2 / span 2;
  grid-row: 2;
  margin: 0px !important;

  display: grid;
  grid-gap: 10px;
}

.fieldVientoReal {
  grid-column: 1;
  grid-row: 1;
  margin: 0px !important;
}
.fieldVientoAparente {
  grid-column: 2;
  grid-row: 1;
  margin: 0px !important;
}

.fieldGps {
  grid-column: 1 / span 3;
  grid-row: 3;
  margin: 0px !important;

  display: grid;
  grid-gap: 10px;
}

.fieldUbicacion {
  grid-column: 1;
  grid-row: 1;
  margin: 0px !important;
}
.fieldVelocidadGps {
  grid-column: 2;
  grid-row: 1;
  margin: 0px !important;
}
.fieldRumboGps {
  grid-column: 3;
  grid-row: 1;
  margin: 0px !important;
}

.fieldMeteo {
  grid-column: 1 / span 3;
  grid-row: 4;
  margin: 0px !important;

  display: grid;
  grid-gap: 10px;
}

.fieldTemperatura {
  grid-column: 1;
  grid-row: 1;
  margin: 0px !important;
}
.fieldHumedad {
  grid-column: 2;
  grid-row: 1;
  margin: 0px !important;
}
.fieldPresion {
  grid-column: 3;
  grid-row: 1;
  margin: 0px !important;
}

.fieldStatus {
  grid-column: 1 / span 3;
  grid-row: 5;
  margin: 0px !important;

  display: grid;
  grid-gap: 10px;
}

.fieldSistema {
  grid-column: 1;
  grid-row: 1;
  margin: 0px !important;
}
.fieldBateria {
  grid-column: 2;
  grid-row: 1;
  margin: 0px !important;
}
.fieldTelefonia {
  grid-column: 3;
  grid-row: 1;
  margin: 0px !important;
}

.fieldStatus2 {
  grid-column: 1 / span 3;
  grid-row: 6;
  margin: 0px !important;

  display: grid;
  grid-gap: 10px;
}

.fieldSentina {
  grid-column: 1;
  grid-row: 1;
  margin: 0px !important;
}
.fieldEscotilla {
  grid-column: 2;
  grid-row: 1;
  margin: 0px !important;
}
.fieldAlarma {
  grid-column: 3;
  grid-row: 1;
  margin: 0px !important;
}

.statisticsPanel{
  width: 100%;
  height: 100%;
}

.plotCanvas{
  width: 100% !important;
  height: 100% !important;
}


@media screen and (max-width: 1200px){
  .statField{
    display: block;
  }
}
@media screen and (min-width: 1201px){
  .statField{
    display: inline-block;
    margin-left: 35px;
    margin-right: 35px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 1200px){
  .statsUp{
    width: 100%;
    height: 25%;
    display: inline-block;
  }
  .statsDown{
    width: 100%;
    height: 75%;
  }
}
@media screen and (min-width: 1201px){
  .statsUp{
    width: 100%;
    height: 10%;
    display: inline-block;
  }
  .statsDown{
    width: 100%;
    height: 90%;
  }
}
